import React, { useContext } from 'react';
import { getGlobal } from 'reactn';
import { Animated, StyleSheet, Text, View } from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import AnimateNumber from 'react-native-animate-number';
import { v4 } from 'uuid';
import { playColRef, rankDocRef } from '../config/firestoreRefs';
import UserContext from "../context/UserContext";

// const {ConstantClass} = require('../utils/Constant');

function TimeComponent(props) {
  const { point, state, countMoney, maxCombo } = props;
  const { highScore } = useContext(UserContext);
  // let timer;
  const onComplete = async () => {
    const { user } = getGlobal();
    if (!user.uid || !rankDocRef())
      return null;
    try {
      const snap = await rankDocRef().get();
      if (!snap.exists) {
        await rankDocRef().set({
          userId: user.uid,
          point: point,
          maxCombo: maxCombo,
          nickname: user.nickname,
          photoURL: user.photoURL,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      } else {
        if (!snap.data()?.point || snap.data().point < point)
          await rankDocRef().set({
            point: point,
            nickname: user.nickname,
            photoURL: user.photoURL,
            updatedAt: new Date(),
          }, {merge: true});

        if (!snap.data()?.maxCombo || snap.data().maxCombo < maxCombo)
          await rankDocRef().set({
            maxCombo: maxCombo,
            nickname: user.nickname,
            photoURL: user.photoURL,
            updatedAt: new Date(),
          }, {merge: true});
      }

      const playId = 'play_id_' + v4();
      await playColRef().doc(playId).set({
        playId,
        duration: state.duration,
        countMoney: countMoney,
        point: point,
        maxCombo: maxCombo,
        createdAt: new Date(),
        userId: user.uid,
      });

    } catch (e) {

    }
  };

  return (
      <View style={styles.header1}>
        <View style={styles.time}>
          <CountdownCircleTimer
              key={state.key}
              size={70}
              isPlaying={state.isPlaying}
              duration={state.duration}
              initialRemainingTime={state.initialRemainingTime}
              colors={[
                ['#00ff37', 0.4],
                ['#ffb301', 0.4],
                ['rgba(255,0,0,0.49)', 0.3],
              ]}
              // rotation="counterclockwise" // dao nguoc thoi gian chay tu trai sang phai
              onComplete={() => {
                onComplete().then(() => {
                  props.navigation.replace('Result',
                    { state: state, point: point, maxCombo: maxCombo, highScore: highScore });
                }).catch(e => {
                  return null;
                });
                // return [true, 1500]; // repeat animation in 1.5 seconds
              }}
              renderAriaTime={(time) => {
                if (props.forwardedRef.current) {
                  props.forwardedRef.current['elapsedTime'] = time.elapsedTime;
                  props.forwardedRef.current['remainingTime'] = time.remainingTime;
                }
              }}
          >
            {({remainingTime, animatedColor}) => (
                <Animated.Text
                    style={{...styles.remainingTime, color: animatedColor}}>
                  {
                    remainingTime === 0 ?
                        <Text style={styles.tooLate}>Hết giờ</Text>
                        :
                        remainingTime
                  }
                </Animated.Text>
            )}
          </CountdownCircleTimer>
        </View>
        <View style={styles.moneyPoint}>
          <View style={styles.header1}>
            <Text style={styles.textHeader}>Điểm</Text>
            <Text style={styles.textValue}>
              {
                point ? <AnimateNumber value={point}
                                       countBy={1}
                                       onProgress={() => {
                                       }}/>
                    : <Text>0</Text>
              }

            </Text>
          </View>
          <View style={styles.header1}>
            <Text style={styles.textHeader}>Tờ tiền</Text>
            <Text style={[
              styles.textValue,
              {
                color: countMoney > 20
                    ? 'red'
                    : 'black',
              }]}>{countMoney}/20</Text>
          </View>
        </View>
      </View>
  );
};

export default React.forwardRef(
    (props, ref) => <TimeComponent {...props} forwardedRef={ref}/>);
const styles = StyleSheet.create({
  textHeader: {
    // flex: 1,
    textAlign: 'left',
    fontSize: 15,
    marginTop: 5,
  },
  textValue: {
    fontSize: 28,
    // color: yellow900,
  },
  header1: {
    flex: 1,
    justifyContent: 'center'
  },
  moneyPoint: {
    flex: 1,
    flexDirection: 'row',
  },
  time: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  remainingTime: {
    fontSize: 20,
  },
  tooLate: {
    fontSize: 12,
  },
});
