import React, {createContext, useContext, useEffect, useState} from 'react';
import AppLoading from 'expo-app-loading';
import {rankColRef} from '../config/firestoreRefs';
import {useCollection} from 'react-firebase-hooks/firestore';
import {concat} from 'lodash';

const RankContext = createContext({});

const RankProvider = ({children}) => {
  const val = useRank();

  if (val.loading) return <AppLoading/>;
  return (
      <RankContext.Provider value={val}>
        {children}
      </RankContext.Provider>
  );
};

const useRank = () => {
  const [rankCol, loading, error] = useCollection(
      rankColRef().orderBy('point', 'desc').limit(10),
  );
  const [last, setLast] = useState(null);

  const [ranks, setRanks] = useState([]);
  const [hasMore, setHasMore] = React.useState(true);

  useEffect(() => {
    if (!rankCol || rankCol.empty)
      return;

    const data = rankCol.docs.map(doc => ({...doc.data(), id: doc.id}));
    setLast(rankCol.docs[rankCol.docs.length - 1]);
    setRanks([...data]);

  }, [rankCol, error]);

  const onNext = async () => {
    if (!last && !hasMore)
      return null;

    try {
      const nextRanks = await rankColRef().
          orderBy('point', 'desc').
          startAfter(last).
          limit(10).
          get();

      let lastVisibleColl = nextRanks.docs[nextRanks.docs.length - 1];

      if (lastVisibleColl !== undefined) {
        let newData = nextRanks.docs.map(doc => ({...doc.data(), id: doc.id}));
        let newRanks = concat(ranks, newData);
        setRanks([...newRanks]);
        setLast(lastVisibleColl);
      } else {
        setHasMore(false);
      }

    } catch (e) {
      console.log(e.toString());
    }
  };

  return {ranks, setRanks, last, setLast, onNext, hasMore};
};

export default RankContext;
export const useRanks = (last) => useContext(RankContext);
export {
  RankProvider,
};
