import React, {createContext, useEffect} from 'react';
import {useGlobal} from 'reactn';
import {auth, db} from '../config/firebaseConfig';
import {useAuthState} from 'react-firebase-hooks/auth';
import {myDoc as myDocument} from '../config/firestoreRefs';
import {useDocument, useDocumentData} from 'react-firebase-hooks/firestore';
import AppLoading from 'expo-app-loading';

const UserContext = createContext({});

const UserProvider = ({children}) => {
  const val = useUser();

  if (val.loading) return <AppLoading/>;
  return (
      <UserContext.Provider value={val}>
        {children}
      </UserContext.Provider>
  );
};

const useUser = () => {
  const [, setUser] = useGlobal('user');
  const [user, loading, error] = useAuthState(auth);
  const [myDoc, myDocLoading, myDocError] = useDocument(user?.uid ?
      myDocument() : null,
  );
  const [highScore, hsLoading, hsError] = useDocumentData(
      db.doc(`ranks/${user?.uid}`));

  // const {ranks} = useContext(RankContext);
  // const [highScore, setHighScore] = React.useState(null);

  // useEffect(() => {
  //   getPoint();
  // }, [user, ranks, myDoc]);

  // const getPoint = () => {
  //   if (!user?.uid)
  //     return;
  //   const idx = findIndex(ranks, {userId: user.uid});
  //   if (idx !== -1) {
  //     setHighScore(ranks[idx]);
  //   }
  // };
  useEffect(() => {
    if (loading || myDocLoading) return;
    let unsubDoc;
    if (user) {
      const _user = {
        ...user.toJSON(),
        id: myDoc?.id,
        docId: myDoc?.id, ...myDoc?.data(),
        nickname: myDoc?.data()?.nickname || myDoc?.data()?.displayName || '',
      };
      setUser({..._user});
    } else {
      onLoggedOut();
    }
    return () => {
      if (typeof unsubDoc === 'function') unsubDoc();
    };

  }, [user, loading, myDoc, myDocLoading]);

  const onLoggedOut = async () => {
    await setUser({});
  };

  return {user, loading, error, highScore, hsLoading};
};

export default UserContext;
export {
  UserProvider,
};
