const images = {
  '1k': require('../assets/1k.png'),
  '2k': require('../assets/2k.png'),
  '5k': require('../assets/5k.png'),
  '10k': require('../assets/10k.png'),
  '20k': require('../assets/20k.png'),
  '50k': require('../assets/50k.png'),
  '100k': require('../assets/100k.png'),
  '200k': require('../assets/200k.png'),
  '500k': require('../assets/500k.png'),
  'avt': require('../assets/avt.jpg'),
};

export default images;
