import React, { useContext, useEffect, useRef, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from './components/HomeScreen';
import PlayScreen from './components/GamePlay';
import SettingScreen from './components/SettingScreen';
import RankScreen from './components/RankScreen';
import HelpScreen from './components/HelpScreen';
import { navigationRef } from './config/NavigationServices';
import { NavigationContainer } from '@react-navigation/native';
import UserContext from './context/UserContext';
import Constants from 'expo-constants';
import ResultScreen from './components/ResultScreen';
import * as Analytics from 'expo-firebase-analytics';

const Stack = createStackNavigator();

const getActiveRouteName = state => {
  const route = state.routes[state.index];

    if (route.state) {
        // Dive into nested navigators
        return getActiveRouteName(route.state);
    }
    return route.name;
};

export default function AppContainer() {
    const routeNameRef = useRef();
    const {user, loading, error} = useContext(UserContext);
    const [initialRouteName, setInitialRouteName] = useState(null);

    useEffect(() => {
        setInitialRouteName('Home');
        // if (loading) return;
        // if (!isEmpty(user)) {
        //   setInitialRouteName('Home');
        // } else
        //   setInitialRouteName('LoginRegister');
    }, [user, loading, error]);

    useEffect(() => {
        const state = navigationRef.current.getRootState();
        // Save the initial route name
        routeNameRef.current = getActiveRouteName(state);
    }, []);

    const updateNavState = state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = getActiveRouteName(state);
        console.log(currentRouteName);
        if (previousRouteName !== currentRouteName) {
            // The line below uses the expo-firebase-analytics tracker
            // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
            // Change this line to use another Mobile analytics SDK
            Analytics.setCurrentScreen(currentRouteName);
        }
        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
    };

    return (
        <NavigationContainer
            ref={navigationRef}
            onStateChange={updateNavState}
        >
            <Stack.Navigator
                initialRouteName={initialRouteName}
                screenOptions={{
                    headerShown: true,
                    headerStyle: {
                        height: Constants.statusBarHeight,
                    },
                    headerForceInset: {top: 'never', bottom: 'never'},
                }}
            >
                <Stack.Screen
                    name="Home"
                    component={HomeScreen}
                    options={{
                        title: 'Rèn luyện kĩ năng trả tiền',
                        ...optionStyle
                    }}
                />
                <Stack.Screen
                    name="Play"
                    component={PlayScreen}
                    options={{
                        title: 'Game play',
                        ...optionStyle
                    }}
                />
                <Stack.Screen
                    name={'Rank'}
                    component={RankScreen}
                    options={{
                        title: 'Bảng xếp hạng',
                        ...optionStyle
                    }}
                />
                <Stack.Screen
                    name={'Tips'}
                    component={HelpScreen}
                    options={{
                        title: 'Típ hướng dẫn',
                        ...optionStyle
                    }}
                />
                <Stack.Screen
                    name={'Setting'}
                    component={SettingScreen}
                    options={{
                        title: 'Cài đặt trò chơi',
                        ...optionStyle
                    }}
                />
                <Stack.Screen
                    name={'Result'}
                    component={ResultScreen}
                    options={{
                        title: 'Tổng kết điểm số và hạng',
                        ...optionStyle
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
}
const optionStyle = {
    headerLeft: null,
    headerTitle: null,
    headerRight: null,
}
