import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View, } from 'react-native';
import firebase from 'firebase/app';
import { userColRef } from '../../config/firestoreRefs';
import ModalComponent from './ModalComponent';

export default function InputNameModal(props) {
  const { navigation, visible, setVisible, updateHighScoreUser } = props;
  const [enteredName, setEnteredName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  function onBack() {
    setVisible(false);
  }

  async function onAccept() {
    if (!enteredName || enteredName && !enteredName.split(' ').filter(x => x).length) return;

    setLoading(true);
    try {
      const { user } = await firebase.auth().signInAnonymously();

      if (!user)
        return;
      const userInfo = {
        uid: user.uid,
        email: enteredName,
        isAnonymous: user.isAnonymous,
        nickname: enteredName,
        lastLogin: new Date(),
        photoURL: null
      }
      await userColRef().doc(user.uid).set(userInfo, { merge: true });

      await updateHighScoreUser(userInfo)
      navigation.replace('Home');
      setVisible(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const textInput = (
    <TextInput
      style={styles.textInput}
      autoCompleteType={'name'}
      placeholder="Hãy nhập nick name của bạn!"
      autoFocus={true}
      value={enteredName}
      onChangeText={(text) => setEnteredName(text)}
    />
  );

  return (
    <View>
      <TouchableOpacity onPress={() => setVisible(true)}
                        style={styles.nicknameBtn}>
        <Text style={styles.textSign}>Điền nickname</Text>
      </TouchableOpacity>
      {
        Boolean(visible) &&
        <ModalComponent
          modalVisible={visible}
          setModalVisible={setVisible}
          title='Nhập nick name của bạn'
          content={textInput}
          buttonLeftText='Lưu lại'
          buttonRightText='Huỷ bỏ'
          onResume={onAccept}
          onLogout={onBack}
          isText={false}
          loading={loading}
          disable={!enteredName ||
          (enteredName && !enteredName.split(' ').filter(x => x).length)}
        />
      }
      {/*<Modal*/}
      {/*    animationType="slide"*/}
      {/*    transparent={true}*/}
      {/*    visible={visible}*/}
      {/*    onRequestClose={() => {*/}
      {/*    }}*/}
      {/*>*/}
      {/*  <View style={styles.centeredView}>*/}
      {/*    <View style={styles.modalView}>*/}
      {/*      <Text style={styles.modalText}>{title}</Text>*/}
      {/*      <View style={styles.contentText}>*/}
      {/*        <Input*/}
      {/*            inputContainerStyle={{width: 200}}*/}
      {/*            value={enteredName}*/}
      {/*            onChangeText={text => setEnteredName(text)}*/}
      {/*        />*/}
      {/*      </View>*/}
      {/*      <View style={styles.buttonActions}>*/}
      {/*        <Pressable*/}
      {/*            style={[styles.button, styles.buttonExit]}*/}
      {/*            onPress={onBack}*/}
      {/*        >*/}
      {/*          <Text style={styles.textStyle}>{buttonLeftText}</Text>*/}
      {/*        </Pressable>*/}
      {/*        {*/}
      {/*          loading ?*/}
      {/*              <ActivityIndicator style={styles.button} size="small"*/}
      {/*                                 color="#0000ff"/>*/}
      {/*              :*/}
      {/*              <Pressable*/}
      {/*                  style={[styles.button, styles.buttonContinue]}*/}
      {/*                  onPress={onAccept}*/}
      {/*                  disabled={loading}*/}
      {/*              >*/}
      {/*                <Text style={styles.textStyle}>{buttonRightText}</Text>*/}
      {/*              </Pressable>*/}
      {/*        }*/}

      {/*      </View>*/}
      {/*    </View>*/}
      {/*  </View>*/}
      {/*</Modal>*/}
    </View>
  );
}
const styles = StyleSheet.create({
  textInput: {
    height: 40,
    fontFamily: 'VT',
    fontSize: 20,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  textSign: {
    fontSize: 23,
    textAlign: 'center',
    fontFamily: 'VT',
    width: '100%',
    textAlignVertical: 'center',
    height: 24
  },
  nicknameBtn: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 7,
  },

});
