import {dofMoney} from "./constants";

export default (money, lever) => {
    let next = money
    let result = {}
    let count = 0
    dofMoney.forEach((val, index) => {
        if (index === 0 && lever < 4) return;
        if (index === 1 && lever < 3) return;
        if (Math.floor(next / val)) {
            result[`${val}k`] = Math.floor(next / val)
            count += Math.floor(next / val)
            next = next % val
        }
    })
    return {giveBack: result, count}
}
