const RandomMoney = () => {
    let data = {}, money = 0;
    let count = 0
    data["500k"] = 0;
    data["200k"] = 0;
    data["100k"] = 3;
    money = 100 * data["100k"];
    count = 3;

    let random50 = Math.floor(Math.random() * 3) + 1;
    money += random50 * 50;
    count += random50;
    data["50k"] = random50;

    let random20 = Math.floor(Math.random() * 3) + 1;
    money += random20 * 20;
    count += random20;
    data["20k"] = random20;

    let random10 = Math.floor(Math.random() * 3) + 1;
    money += random10 * 10;
    count += random10;
    data["10k"] = random10;

    let random5 = Math.floor(Math.random() * 3) + 1;
    money += random5 * 5;
    count += random5;
    data["5k"] = random5;

    let random2 = Math.floor(Math.random() * 3) + 1;
    money += random2 * 2;
    count += random2;
    data["2k"] = random2;

    let random1 = Math.floor(Math.random() * 3) + 1;
    money += random1;
    count += random1;
    data["1k"] = random1;

    data["money"] = money;
    data["count"] = count;
    return data;
}
module.exports = {
    RandomMoney
}
