import React, {useEffect, useRef} from 'react';
import {Animated, PanResponder, StyleSheet, Text, View} from 'react-native'

function ResultAnimate({result}) {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const pan = useRef(new Animated.ValueXY({
        x: 0, y: 100
    })).current;

    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: Animated.event(
                [
                    null,
                    {dx: pan.x, dy: pan.y}
                ],
                {
                    useNativeDriver: false
                }
            ),
            onPanResponderRelease: () => {
                pan.flattenOffset();
            }
        })
    ).current;

    useEffect(() => {
        if (result)
            fadeIn()
    }, [result])
    const fadeIn = () => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: false
        }).start(() => {
            fadeOut()
        });
        Animated.spring(pan, {
            toValue: {x: 0, y: 0},
            duration: 500,
            useNativeDriver: false,
        }).start()

    };

    const fadeOut = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 500,
            useNativeDriver: false,
        }).start(() => {
            Animated.spring(pan, {
                toValue: {x: 0, y: 100},
                duration: 0,
                useNativeDriver: false,
            }).start()
        });
    };

    return (
        <View style={styles.container}>
            <Animated.View
                style={[
                    styles.fadingContainer,
                    {
                        opacity: fadeAnim,
                        transform: [{translateX: pan.x}, {translateY: pan.y}]
                    }
                ]}
                {...panResponder.panHandlers}
            >
                {
                    result &&
                    <React.Fragment>
                        {
                            result.combo > 1 ?
                                <Text style={[styles.fadingText, styles.combo]}>Combo x {result.combo - 1}</Text>
                                :
                                null
                        }

                        <Text style={styles.fadingText}>{result.text}</Text>
                        <Text style={styles.fadingText}>{result.point > 0 ? "+" : ""} {result.point} Điểm</Text>
                        <Text style={styles.fadingText}>{result.time > 0 ? "+" : ""} {result.time} s</Text>
                    </React.Fragment>
                }

            </Animated.View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    fadingContainer: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: "transparent"
    },
    fadingText: {
        fontSize: 28,
        textAlign: "center",
        margin: 10
    },
    combo: {
        color: "red"
    }
});
export default ResultAnimate;
