import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';

// const ACCESS_TOKEN_KEY = 'accessToken'
export const GOOGLE_TOKEN_KEY = 'googleToken';

export default () => {
  //REQUEST
  axios.interceptors.request.use(
      async (config) => {
        const googleToken = await AsyncStorage.getItem(GOOGLE_TOKEN_KEY);
        if (googleToken) {
          const accessToken = JSON.parse(googleToken).accessToken;
          config.headers['Authorization'] = `Bearer ${accessToken}`;
          // console.log(JSON.parse(googleToken));
        }

        return config;
      },
      error => {

        return Promise.reject(error);
      });

  //RESPONSE
  axios.interceptors.response.use(
      function(response) {
        return response;
      },
      async function(error) {
        const originalRequest = error.config;
        if (!error.response) {
          return Promise.reject(error);
        }
        if (error.response.status === 401) {
          // google token expired
          const data = await refreshToken();
          if (data) {
            originalRequest.headers['Authorization'] = 'Bearer ' +
                data.accessToken;
            return axios(originalRequest);
          } else {
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      },
  );
}
const refreshToken = async () => {
  try {
    const googleToken = await AsyncStorage.getItem(GOOGLE_TOKEN_KEY);
    if (!googleToken) return null;
    const refreshToken = JSON.parse(googleToken).refreshToken;
    if (!refreshToken)
      return null;
    // refresh
    await axios.post('https://oauth2.googleapis.com/token');
  } catch (e) {
    return null;
  }
};


