import React, {useContext} from 'react';
import {useGlobal} from 'reactn';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import CashLottie from './Home/CashLottie';
import UserContext from '../context/UserContext';
import ButtonCustom from './Custom/ButtonCustom';
import AppLoading from 'expo-app-loading';
import {isEmpty} from 'lodash';

function HomeScreen(props) {
  const [user] = useGlobal('user');
  const {highScore, hsLoading} = useContext(UserContext);

  return (
      <View style={styles.container}>
        {
          !isEmpty(user?.nickname) &&
          <Text style={styles.instructions}>
            Chào <Text>{user?.nickname}</Text> {'\n'}
            {
              hsLoading ?
                  <AppLoading/>
                  :
                  highScore?.point ?
                      <Text style={styles.instructions}>
                        Thành tích tốt nhất: {highScore.point} pt
                      </Text>
                      :
                      'Bạn chưa lên bảng xếp hạng! Hãy chơi nào!'
            }
          </Text>
        }
        {
          Platform.OS === 'ios' && <CashLottie/>
        }
        {
          Platform.OS === 'android' &&
          <Image
              source={require('../assets/43514-pay-animation.gif')}
              style={styles.bgImage}
              // width={Dimensions.get('screen').width} height={'auto'}
          />
        }
        <View style={styles.bodyHome}>

          <ButtonCustom
              text={'Chơi game'}
              color={['#d42a38', '#b11414', '#92070b', '#62040b']}
              actions={() => props.navigation.replace('Play')}
              containerStyle={styles.containerStyle}
          />

          <ButtonCustom
              text={'Bảng xếp hạng'}
              color={['#24deb3', '#2981b0', '#0b4183', '#123a8d']}
              actions={() => props.navigation.replace('Rank')}
              containerStyle={styles.containerStyle}
          />
        </View>
        <View style={styles.helpStyle}>
          <TouchableOpacity onPress={() => props.navigation.replace('Tips')}
                            style={styles.buttonIcon}>
            <Ionicons
                style={styles.iconStyle}
                name="ios-help-circle-outline"
                size={50}
                color="black"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => props.navigation.replace('Setting')}
                            style={styles.buttonIcon}>
            <Ionicons
                style={styles.iconStyle}
                name="ios-settings-sharp"
                size={50}
                color="black"
            />
          </TouchableOpacity>

        </View>
      </View>
  );
}

export default HomeScreen;

const styles = StyleSheet.create({
  bgImage: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  instructions: {
    width: 300,
    fontSize: 25,
    color: '#000',
    textAlign: 'center',
    fontFamily: 'VT',
    zIndex: 99,
    marginTop: 10,
  },
  button: {
    marginBottom: 10,
    backgroundColor: 'peru',
    padding: 20,
    borderRadius: 20,
  },
  buttonText: {
    width: 200,
    fontSize: 26,
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'VT',
  },
  bodyHome: {
    flex: 1,
    marginVertical: 30,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bodyHomeIos: {
    flex: 1,
    marginVertical: 30,
  },
  iconStyle: {
    zIndex: 3,
  },
  buttonIcon: {
    padding: 10,
    margin: 10,
    borderRadius: 20,
    backgroundColor: 'peru',
  },
  containerStyle: {
    marginBottom: 10,
  },
  helpStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
});
