const firebase = require('firebase/app');
require('firebase/storage');
require('firebase/auth');
require('firebase/firestore');
require('firebase/functions');

const firebaseConfig = {
  apiKey: 'AIzaSyD70UhjmUafj9WDAKhRk80xgECRP8uHDa8',
  authDomain: 'tinh-tien-em-oi.firebaseapp.com',
  projectId: 'tinh-tien-em-oi',
  storageBucket: 'tinh-tien-em-oi.appspot.com',
  messagingSenderId: '782010882963',
  appId: '1:782010882963:web:b5e1a673b7cd15a5702666',
  measurementId: 'G-0SHZZ4YTD1',
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

module.exports = exports = {
  db,
  auth,
  storage,
  functions,
};
