import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { StyleSheet, Text, TextInput, TouchableOpacity, View, } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import GoogleSignIn from './Login/Google/GoogleSignIn';
import AppleLogin from './Login/Apple/AppleLogin';
import { auth } from '../config/firebaseConfig';
import InputNameModal from './Modal/InputName';
import { Button, Icon, Overlay } from 'react-native-elements';
import UserCustom from './Settings/UserCustom';
import { isEmpty } from 'lodash';
import { rankColRef, userColRef } from '../config/firestoreRefs';
import ModalComponent from './Modal/ModalComponent';
import IconExitCustom from './Custom/IconExitCustom';
import { ConstantClass } from '../utils/Constant';
import AsyncStorage from '@react-native-async-storage/async-storage';

const MenuIcon = ({ name, type = 'antdesign', ...props }) => {
  return <Icon name={name} type={type} size={30}
    // color={lightBlue500}
               {...props} />;
};

function SettingScreen(props) {
  const [user] = useGlobal('user')
  const [visible, setVisible] = React.useState(false);
  const [isLogout, setIsLogout] = React.useState(false);
  const [nickName, setNickName] = useState('');
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [volume, setVolume] = useState(null);

  useEffect(() => {
    getMute();
  }, [user]);

  const getMute = async () => {
    try {
      const value = await AsyncStorage.getItem('volume');
      if (value !== null)
        setVolume(value);
    } catch (e) {
      console.log(e.toString());
    }
  };

  const onUpdated = async () => {
    if (!nickName || (nickName && !nickName.split(' ').filter(x => x).length)) {
      return;
    }
    setLoading(true);
    try {
      //set new data
      const value = {
        uid: info.userId,
        email: info.email,
        nickname: nickName,
        lastLogin: new Date(),
        providerId: info.providerId,
        photoURL: info.photoURL || '',
      }
      await userColRef().doc(info.userId).set(value);
      await updateHighScoreUser(value)
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setNickName('');
      setInfo(null);
      onLoginDone();
    }
  };

  const onCancel = async () => {
    try {
      await onLogOut();
    } catch (e) {
      console.log(e);
    } finally {
      setNickName('');
      setInfo(null);
      onLoginDone();
    }
  };

  const onLogOut = async () => {
    try {
      await auth.signOut();
      await AsyncStorage.clear();
    } catch (e) {
      return null;
    } finally {
      setIsLogout(false);
      props.navigation.replace('Home');
    }
  };

  const updateHighScoreUser = async (user) => {
    try {
      const point = await AsyncStorage.getItem(ConstantClass.AnonymousPoint);
      const maxCombo = await AsyncStorage.getItem(ConstantClass.AnonymousCombo);
      if (!point && !maxCombo)
        return null;
      const rankDocRef = rankColRef().doc(user.uid)
      const snap = await rankDocRef.get()
      if (!snap.exists) {
        await rankDocRef.set({
          userId: user.uid,
          point: Number(point),
          maxCombo: Number(maxCombo),
          nickname: user.nickname,
          photoURL: user.photoURL,
          createdAt: new Date(),
          updatedAt: new Date(),
        })
        return;
      }
      if (snap.data().point && snap.data().point < Number(point || 0)) {
        await rankDocRef.set({
          point: Number(point),
          nickname: user.nickname,
          photoURL: user.photoURL,
          updatedAt: new Date(),
        })
      }
      if (snap.data().maxCombo && snap.data().maxCombo < Number(maxCombo || 0)) {
        await rankDocRef.set({
          maxCombo: Number(maxCombo),
          nickname: user.nickname,
          photoURL: user.photoURL,
          updatedAt: new Date(),
        })
      }
    } catch (e) {
      console.log(e.toString())
    }
  }

  const onLoginDone = async (user) => {
    if (!isEmpty(user))
      await updateHighScoreUser(user);

    props.navigation.replace('Home');
  };

  const handleChangeVolume = async () => {
    try {
      const value = await AsyncStorage.getItem('volume');

      if (value !== null) {

        const newValue = value === 'ON' ? 'OFF' : 'ON';

        await AsyncStorage.setItem('volume', newValue);
        setVolume(newValue);
      } else {
        await AsyncStorage.setItem('volume', 'OFF');
        setVolume('OFF');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const textInput = (
    <TextInput
      style={styles.textInputStyle}
      autoCompleteType={'name'}
      placeholder="Hãy nhập nick name của bạn!"
      autoFocus={true}
      value={nickName}
      onChangeText={(text) => setNickName(text)}
    />
  );

  return (
    <View style={styles.container}>
      <IconExitCustom
        onExit={() => props.navigation.replace('Home')}
      />
      {
        auth?.currentUser &&
        <UserCustom/>
      }
      <View style={styles.headerSetting}>
        <Text style={styles.textVolume}> Âm thanh </Text>
        <TouchableOpacity onPress={handleChangeVolume}
                          style={styles.buttonIcon}>
          {
            volume === 'OFF' ?
              <Ionicons
                style={styles.iconStyle}
                name="ios-volume-mute"
                size={50}
                color="black"
              />
              : <Ionicons
                style={styles.iconStyle}
                name="ios-volume-high"
                size={50}
                color="black"/>
          }
        </TouchableOpacity>
      </View>

      {
        !auth?.currentUser ?
          <View style={styles.bodySetting}>

            <GoogleSignIn setInfo={setInfo} onDone={onLoginDone}/>
            <AppleLogin setInfo={setInfo} onLoginDone={onLoginDone}/>

            <Text style={styles.Or}>OR</Text>
            <TouchableOpacity
              onPress={() => setVisible(true)}
              style={styles.buttonSign}
              disabled={auth?.currentUser}
            >
              <InputNameModal updateHighScoreUser={updateHighScoreUser} visible={visible} setVisible={setVisible}
                              navigation={props.navigation}/>
            </TouchableOpacity>
          </View>
          :
          <View style={styles.bodySetting}>

          </View>
      }

      {
        auth?.currentUser &&
        <TouchableOpacity onPress={() => setIsLogout(true)}
                          style={styles.buttonIconLogout}>
          <MenuIcon name={'logout'}/>
        </TouchableOpacity>
      }

      <Overlay isVisible={isLogout}>
        <View style={styles.buttonGroup}>
          <Button
            title={' Đăng xuất'}
            titleStyle={{ color: 'red' }}
            type={'clear'}
            buttonStyle={styles.enableLogout}
            icon={<MenuIcon name={'logout'} color={'red'}/>}
            onPress={onLogOut}
          />
          <Button
            type={'clear'}
            title={'Cancel'}
            buttonStyle={styles.hidenLogout}
            onPress={() => setIsLogout(false)}
          />
        </View>

      </Overlay>

      {
        !isEmpty(info) &&
        <ModalComponent
          modalVisible={Boolean(info)}
          setModalVisible={setInfo}
          title="Nhập nick name của bạn"
          content={textInput}
          buttonLeftText="Lưu lại"
          buttonRightText="Huỷ bỏ"
          onResume={onUpdated}
          onLogout={onCancel}
          isText={false}
          disable={!nickName ||
          (nickName && !nickName.split(' ').filter(x => x).length)}
          loading={loading}
        />
      }
    </View>
  );
}

export default SettingScreen;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    backgroundColor: '#fff',
  },
  buttonIcon: {
    width: 100,
    fontSize: 20,
    color: '#fff',
    textAlign: 'center',
  },
  textVolume: {
    fontSize: 35,
    color: '#d99e00',
    marginRight: 100,
    fontFamily: 'VT',
  },
  headerSetting: {
    marginLeft: 20,
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  bodySetting: {
    flex: 4,
    justifyContent: 'center',
    width: 300,
  },
  iconStyle: {
    marginLeft: 25,
  },
  buttonSign: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 15,
    borderRadius: 5,
    flexDirection: 'row',
  },
  Or: {
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'VT',
    fontSize: 22,
  },
  buttonIconLogout: {
    width: 60,
    padding: 10,
    marginBottom: 10,
    borderRadius: 24,
    backgroundColor: 'peru',
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInputStyle: {
    height: 40,
    fontFamily: 'VT',
    fontSize: 20,
  },
  enableLogout: {
    height: 50,
    margin: 5,
    borderColor: 'red',
  },
  hidenLogout: {
    height: 50,
    margin: 5,
  },
});
