import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from 'firebase/app';
import { Button, Icon } from 'react-native-elements';
import * as Google from 'expo-google-app-auth';
import config from './config';
import { GOOGLE_TOKEN_KEY } from '../../../config/axios';
import { userColRef } from '../../../config/firestoreRefs';

export default function GoogleSignIn({ onDone, setInfo }) {
  const [loading, setLoading] = useState(false);

  const ggLoginAsync = async () => {
    setLoading(true);
    try {
      //login
      const result = await Google.logInAsync(config);
      if (result.type === 'success') {
        await AsyncStorage.setItem(GOOGLE_TOKEN_KEY, JSON.stringify(result));
        // await AsyncStorage.setItem("googleTokenGroup", JSON.stringify(result))
        const { user } = await firebaseAuthSignIn(result);
        // await setUser(user);

        if (!user)
          return;

        let userInfo = {
          userId: user.uid,
          email: user.email,
          nickname: user?.displayName || '',
          lastLogin: new Date(),
          providerId: 'google.com',
          photoURL: user?.photoURL || '',
        };

        const snap = await userColRef().doc(user.uid).get();

        // update last login if exists

        if (snap.exists && snap.data()?.nickname) {
          await userColRef().doc(user.uid).set({
            lastLogin: new Date(),
          }, { merge: true });

          if (onDone) onDone({ ...userInfo, uid: userInfo.userId });
          return;
        }

        // open dialog update nick name
        if (setInfo)
          setInfo({ ...userInfo, uid: userInfo.userId });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const firebaseAuthSignIn = async result => {
    try {
      const credential = firebase.auth.GoogleAuthProvider.credential(
        result.idToken,
        result.accessToken,
      );
      return await firebase.auth().signInWithCredential(credential);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  if (loading) return <ActivityIndicator size="small" color="gray"/>;

  return (
    <View style={{ paddingVertical: 10 }}>
      <Button
        type={'outline'}
        title={' Đăng nhập Google'}
        titleStyle={{ fontSize: 22, fontFamily: 'VT' }}
        icon={<Icon
          name="google"
          type="font-awesome"
          color="#ff0000"
          size={20}
          containerStyle={{
            // margin: 5,
          }}
        />}
        loading={loading}
        onPress={ggLoginAsync}
      />
    </View>
  );
}
