import {createRef} from 'react';

export const navigationRef = createRef();

function navigate(name, params) {
  navigationRef.current && navigationRef.current.navigate(name, params);
}

function replace(name, params) {
  navigationRef.current && navigationRef.current.replace(name, params);
}

// add other navigation functions that you need and export them

export default {
  navigate,
  replace,
};
