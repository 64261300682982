import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, Text, View } from 'react-native';
import { yellow900 } from 'react-native-paper/src/styles/colors';

export default function MoneyComponent(props) {
  const { money, state } = props;

  const fadeAnim = useRef(new Animated.Value(30)).current;
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 40,
      duration: 500,
      useNativeDriver: false
        }).start(() => {
            Animated.timing(fadeAnim, {
                toValue: 30,
                duration: 500,
                useNativeDriver: false
            }).start()
        });
    }, [money])
    const total = state.received.reduce((totalPaid, val) => {
        return totalPaid + parseInt(val.split('k')[0]);
    }, 0);
    const moneyDiff = total - money;
    return (
        <View style={styles.header1}>
            <View>
                <Text style={styles.textHeader}>Tiền phải trả:</Text>
                <Animated.Text style={[styles.textValue, {fontSize: fadeAnim}]}>{money}.000</Animated.Text>
            </View>
        <View>
          <Text style={styles.textHeader}>Tiền đã trả:</Text>
          <Text style={styles.textValue}>{total ? `${total}.000` : total}</Text>
        </View>
        <View>
          <Text style={styles.textHeader}>Tiền thừa:</Text>
          {
            !state.isPlaying ?
                <Text style={styles.textValue}>
                  {moneyDiff
                      ? moneyDiff + '.000'
                      : 0}</Text>
                :
                <Text style={styles.textValue}>
                  {' '}
                </Text>
          }
        </View>
      </View>
  );
}

const styles = StyleSheet.create({
  textHeader: {
    // flex: 1,
    textAlign: 'left',
    fontSize: 15,
    // marginTop: 5,
    fontFamily: 'Play',
  },
  textValue: {
    fontSize: 25,
    fontWeight: 'bold',
    color: yellow900,
  },
  header1: {
    flex: 1,
    justifyContent: 'center',
  },
});
