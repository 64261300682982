import React from 'react';
import {ActivityIndicator, Modal, Pressable, StyleSheet, Text, View,} from 'react-native';

const ModalComponent = (props) => {
  const {
    modalVisible,
    onResume,
    onLogout,
    title = 'Đã tạm dừng.',
    content = null,
    buttonLeftText = 'Chiến tiếp',
    buttonRightText = 'Rời khỏi',
    isText = true,
    disable = false,
    loading = false,
  } = props;

  return (
      <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {

          }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>

            <Text style={styles.modalText}>{title}</Text>

            {
              content ?
                  <View style={styles.contentText}>
                    {
                      isText ?
                          <Text>
                            {content}
                          </Text>
                          :
                          content
                    }

                  </View>
                  :
                  null
            }
            <View style={styles.buttonActions}>
              {
                loading ?
                    <ActivityIndicator size="small" color="gray"
                                       style={styles.ActivityIndicator}/>
                    :
                    <Pressable
                        style={[styles.button, styles.buttonContinue]}
                        onPress={onResume}
                        disabled={disable}
                    >
                      <Text style={styles.textStyle}>{buttonLeftText}</Text>
                    </Pressable>
              }

              <Pressable
                  style={[styles.button, styles.buttonExit]}
                  onPress={onLogout}
              >
                <Text style={styles.textStyle}>{buttonRightText}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    margin: 5,
    minWidth: 100,
    fontFamily: 'VT',
  },
  buttonContinue: {
    backgroundColor: '#2196F3',
  },
  buttonExit: {
    backgroundColor: '#cb1717',
  },
  textStyle: {
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'VT',
    fontSize: 23,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'VT',
    fontSize: 23,
  },
  buttonActions: {
    flexDirection: 'row',
    marginTop: 10,
  },
  contentText: {
    width: '70%',
    margin: 'auto',
  },
  ActivityIndicator: {
    marginRight: 30,
  },
});

export default ModalComponent;
