import {Dimensions} from 'react-native'

const width = Dimensions.get('window').width
export const iosPosition = {
    "1k": {
        top: 0,
        left: 15
    },
    "2k": {
        top: 0,
        left: parseInt((width + 4) / 3)
    },
    "5k": {
        top: 0,
        left: parseInt((width + 4) / 3) * 2 - 15
    },
    "10k": {
        top: 60,
        left: 15
    },
    "20k": {
        top: 60,
        left: parseInt((width + 4) / 3)
    },
    "50k": {
        top: 60,
        left: parseInt((width + 4) / 3) * 2 - 15
    },
    "100k": {
        top: 120,
        left: 15
    },
    "200k": {
        top: 120,
        left: parseInt((width + 4) / 3)
    },
    "500k": {
        top: 120,
        left: parseInt((width + 4) / 3) * 2 -15
    },
}
export const results = [
    {
        text: "Xuất sắc",
        point: 20,
        time: 5,
        id: 1
    },
    {
        text: "Tốt",
        point: 15,
        time: 2,
        id: 2
    },
    {
        text: "Khá",
        point: 10,
        time: 1,
        id: 3
    },
    {
        text: "Chưa đạt",
        point: -5,
        time: -3,
        id: 4
    },

]

export const dofMoney = [500, 200, 100, 50, 20, 10, 5, 2, 1]
