import LottieView from 'lottie-react-native';
import {StyleSheet, View} from 'react-native';
import React, {memo, useEffect, useRef} from 'react';

function CashLottie() {
  const ref = useRef();
  useEffect(() => {
    ref.current.play();
    // ref.current.stop();
  }, []);
  return (
      <View style={styles.animationContainer}>
        <LottieView
            style={styles.lottieViewStyle}
            source={require('./../../assets/43514-pay-animation.json')}
            // autoPlay
            autoSize
            ref={ref}
            loop={false}

            // OR find more Lottie files @ https://lottiefiles.com/featured
            // Just click the one you like, place that file in the 'assets' folder to the left, and replace the above 'require' statement
        />
      </View>
  );
}

export default memo(CashLottie);

const styles = StyleSheet.create({
    animationContainer: {
        flex: 1,
    },
    lottieViewStyle: {
        width: '100%',
    }
});
