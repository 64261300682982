export default function (result, time) {
    // bi tru. thi van tru nhu cu
    if (result.time < 0) {
        return result
    }
    let percent = 1
    if (time <= 5) {
        percent = 1
    } else if (time <= 7) {
        percent = 0.8
    } else {
        percent = 0.6
    }
    return {...result, time: Math.round(percent * result.time), point: Math.round(percent * result.point)}
}
