import React, { Component } from 'reactn';
import { Animated, Dimensions, Image, PanResponder, StyleSheet, } from 'react-native';
import Constants from "expo-constants";

const windowHeight = Dimensions.get('window').height;

class Draggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDraggable: true,
      dropAreaValues: null,
      pan: new Animated.ValueXY({
                x: this.props.isMore ? 1000 : 0, y: this.props.isMore ? -200 : -windowHeight
            }),
            opacity: new Animated.Value(1),
            currentY: 0,
            animateFinished: false
        };
    }

    check(y) {
        return this.props.isDropedInZone(y)
    }

    handlerChangeZone(gesture) {
        if (this.check(gesture['moveY']) === this.check(gesture['y0'])) {
            return;
        }
        if (this.check(gesture.moveY)) {
            if (this.props.copyRandom[this.props.value] <= 0) return;
            if (!this.props.received.includes(this.props.index)) {
                this.props.received.push(this.props.index);
                this.props.dispatch(
                    {type: 'received', received: [...this.props.received]});

            }
            return;
        }
        if (!this.check(gesture.moveY)) {
            let index = this.props.received.lastIndexOf(this.props.index);
            if (index !== -1) {
                this.props.received.splice(index, 1);
            }
            this.props.dispatch(
                {type: 'received', received: [...this.props.received]});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.loading && this.check(this.state.currentY)) {
        //     this.moveOut()
        // }
        // if (!this.props.loading && this.state.currentY === -windowHeight) {
        //     this.reset()
        // }
        if (!this.props.received.length && this.state.animateFinished && (this.state.pan.x._value || this.state.pan.y._value)) {
            // reset this positon
            // console.log('reset ', this.props.index)
            this.reset()
        }
    }

    reset() {
        Animated.spring(this.state.pan, {
            toValue: {x: 0, y: 0},
            friction: 9,
            useNativeDriver: false,
        }).start()
    }

    componentDidMount() {
        // console.log(this.props.khay);
        if (this.props.isMore) {
            Animated.timing(this.state.pan, {
                toValue: {x: 0, y: 0},
                duration: 500,
                useNativeDriver: false,
            }).start(() => {
                this.setState({
                    animateFinished: true
                })
            })
            return
        }

        if (!this.props.khay)
            return Animated.spring(this.state.pan, {
                toValue: {x: 0, y: 0},
                friction: 9,
                useNativeDriver: false,
            }).start(() => {
                this.setState({
                    animateFinished: true
                })
            })

        Animated.spring(this.state.pan, {
          toValue: { x: 5, y: -this.props.khay - Constants.statusBarHeight },
          friction: 9,
          useNativeDriver: false,
        }).start(() => {
            Animated.spring(this.state.pan, {
                toValue: {x: 0, y: 0},
                friction: 9,
                useNativeDriver: false,
            }).start(() => {
                this.setState({
                    animateFinished: true
                })
            })
        })

    }

    componentWillUnmount() {
        this.setState({
            showDraggable: true,
            dropAreaValues: null,
            pan: new Animated.ValueXY(),
            opacity: new Animated.Value(0),
            currentY: 0,
        })
    }


    UNSAFE_componentWillMount() {
        // Initialize PanResponder with move handling
        this.panResponder = PanResponder.create({
            onMoveShouldSetPanResponder: () => this.state.animateFinished && !this.props.loading,
            onStartShouldSetPanResponder: () => {
                this.setState({
                    idx: this.props.received.length + 1
                })
                return false
            },
            onPanResponderGrant: (e, gestureState) => {
                this.currentY = gestureState.moveY;
                this.state.pan.setOffset({
                    x: this.state.pan.x._value,
                    y: this.state.pan.y._value,
                });

            },
            onPanResponderMove: Animated.event([
                    null, {dx: this.state.pan.x, dy: this.state.pan.y},
                ],
                {
                    useNativeDriver: false
                },
            ),
            onPanResponderRelease: (e, gesture) => {
              this.state.pan.flattenOffset();
              gesture.value = this.props.value;
              // console.log("gesture",gesture);
              if (this.check(e.nativeEvent.pageY - e.nativeEvent.locationY)) {
                Animated.timing(this.state.opacity, {
                  toValue: 1,
                  duration: 0,
                  useNativeDriver: false,
                }).start(() =>
                  this.setState({
                    showDraggable: false,
                    currentY: gesture.moveY,
                  }),
                );
                    if (this.props.copyRandom[this.props.value] <= 0) return;
                    if (!this.props.received.includes(this.props.index)) {
                        this.props.received.push(this.props.index);
                        this.props.dispatch(
                            {type: 'received', received: [...this.props.received]});

                    }
                    return;
                } else {
                    this.setState({
                        currentY: 0,
                        idx: null
                    })
                    Animated.spring(this.state.pan, {
                        toValue: {x: 0, y: 0},
                        friction: 9,
                        useNativeDriver: false,
                    }).start(() => {

                    });
                    let index = this.props.received.lastIndexOf(this.props.index);
                    if (index !== -1) {
                        this.props.received.splice(index, 1);
                    }
                    this.props.dispatch(
                        {type: 'received', received: [...this.props.received]});
                }
            },
        });

    }

    render() {
        const panStyle = {
            transform: this.state.pan.getTranslateTransform(),
            position: 'absolute',
            left: 0,
            top: 0,
            ...this.props.style
        };
        return (
            <Animated.View
                {...this.panResponder.panHandlers}
                style={[panStyle, this.state.idx ? {zIndex: this.state.idx} : {}]}
            >
                <Image source={this.props.src} style={styles.imageMoney}/>
            </Animated.View>
        );
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

let styles = StyleSheet.create({
    imageMoney: {
        alignItems: 'center',
        width: 105,
        height: 47,
        marginTop: 10,
    },
});

export default React.forwardRef(
    (props, ref) => <Draggable {...props} forwardedRef={ref}/>);
