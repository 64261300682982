import React, {useEffect, useRef} from 'react';
import {Animated, Image, PanResponder, StyleSheet, Text, View} from 'react-native'
import images from "../common";

function DraggTips() {
    const fadeAnim = useRef(new Animated.Value(1)).current;
    const pan = useRef(new Animated.ValueXY({
        x: 0, y: 100
    })).current;

    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => false,
            onPanResponderGrant: () => {
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: Animated.event(
                [
                    null,
                    {dx: pan.x, dy: pan.y}
                ],
                {
                    useNativeDriver: false
                }
            ),
            onPanResponderRelease: () => {
                pan.flattenOffset();
            }
        })
    ).current;

    useEffect(() => {
      fadeIn()
    }, [])
    const fadeIn = () => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: false
        }).start(() => {
            fadeOut()
        });
        Animated.spring(pan, {
            toValue: {x: 0, y: -150},
            duration: 500,
            useNativeDriver: false,
        }).start()

    };

    const fadeOut = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false
        }).start(() => {
            Animated.spring(pan, {
                toValue: {x: 0, y: 1000},
                duration: 200,
                useNativeDriver: false,
            }).start()
        });
    };

    return (
        <Animated.View
            style={[
                styles.fadingContainer,
                {
                    opacity: fadeAnim,
                    transform: [{translateY: pan.y}],
                },
            ]}
            {...panResponder.panHandlers}
        >
            <View style={styles.tips}>
                <Text style={styles.fadingText}>Kéo tiền lên</Text>
                {/*<AntDesign name="arrowup" size={24} color="black"/>*/}
                <Image source={images["200k"]} style={styles.imageMoney}/>
                <Image source={require("../../assets/finger.png")} style={styles.finger}/>
            </View>
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    fadingContainer: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: "transparent",
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999
    },
    fadingText: {
        fontSize: 28,
        textAlign: "center",
        margin: 10,
        color: "red"
    },
    tips: {
        // flex: 1,
        position: "absolute",
        top: -100,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        // borderColor: "red",
        // borderWidth: 2,
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10
    },
    imageMoney: {
        alignItems: 'center',
        width: 105,
        height: 47,
        marginTop: 10,
    },
    finger: {
        position: "absolute",
        bottom: -47,
        alignItems: 'center',
        width: 105,
        height: 100,
        marginTop: 10,
    }

});
export default DraggTips;
