import React from 'react';
import {
    Image,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native';
import IconExitCustom from './Custom/IconExitCustom';

const helpInit = require('../assets/degin.jpg');
const helpPointTime = require('../assets/point_time.jpg');
const helpEnd = require('../assets/end-game.jpg');

function HelpScreen(props) {

    const strHDC = `- Bạn cần kéo thả tờ tiền từ ví của mình thả vào khay đến khi số tiền đã trả lớn hơn hoặc bằng số tiền phải trả click "Trả tiền" `;
    const strTDvaTG = `
    - Xuất sắc: +5s, +20đ (Trả đúng số tiền phải trả).\n
    - Tốt: +2s, +15đ (Trả số tiền thừa là bội của 10k và số tờ ít hơn hoặc bằng 2 tờ).\n
    - Khá: +1s, +10đ (Trả số tiền thừa khác bội của 10k và số tờ ít hơn hoặc bằng 3 tờ).\n
    - Chưa đạt: -3s, -5đ .
    
    Chú ý: 
    - Các trường hợp được cộng thêm giây nếu thời gian suy nghĩ trả lên khay:
        + Lớn hơn bằng 7s thì nhận được 60% giây được cộng
        + Lớn hơn bằng 5s thì nhận được 80% giây được cộng
    `;
    const strLerver = `
        - Lever bậc  "Tiểu học": Điểm số nhỏ hơn bằng 50.\n
        - Lever bậc  "Trung học": Điểm số lớn hơn 50 và nhỏ hơn bằng 150.\n
        - Lever bậc  "Đại học":  Điểm số lớn hơn 150 và nhỏ hơn bằng 300.\n
        - Lever bậc  "Người đi làm": Điểm số lớn hơn 300 và nhỏ hơn bằng 500.\n
        - Lever bậc  "Kế toán trưởng": Điểm số lớn hơn 500. `;

    return (
        <SafeAreaView style={styles.container}>
            <IconExitCustom
                onExit={() => props.navigation.replace('Home')}
            />
            <ScrollView style={styles.scrollView}>
                <Text style={styles.instructions}>
                    Rèn luyện kĩ năng trả tiền
                </Text>
                <View>
                    <Text style={styles.textHeader}>I. Hướng dẫn chơi</Text>
                    <Image source={helpInit} style={styles.img}
                           resizeMode="contain"/>
                    <Text style={styles.textStyle}>
                        {strHDC}
                    </Text>
                </View>
                <View>
                    <Text style={styles.textHeader}>II. Hướng dẫn tính điểm và thời gian</Text>
                    <Image source={helpPointTime} style={styles.img}
                           resizeMode="contain"/>
                    <Text style={styles.textStyle}>
                        {strTDvaTG}
                    </Text>
                </View>
                <View>
                    <Text style={styles.textHeader}>III. Hướng dẫn cấp bậc trong game</Text>
                    <Image source={helpEnd} style={styles.img}
                           resizeMode="contain"/>
                    <Text style={styles.textStyle}>
                        {strLerver}
                    </Text>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default HelpScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 15,
        backgroundColor: '#fff',
        position: 'relative',
    },
    instructions: {
        textAlign: 'center',
        color: 'red',
        fontSize: 32,
        marginBottom: 10,
    },
    textStyle: {
        fontSize: 18,
        marginBottom: 10,
    },
    helpImg: {
        width: 300,
        overflow: "scroll"
    },
    textHeader: {
        marginVertical: 10,
        fontSize: 26,
        color: "#dc8707"
    },
    img: {
        flex: 1,
        aspectRatio: 1,
        height: undefined,
        width: undefined
    }
});
