import React from 'react';
import { Text, View } from 'react-native';
import ModalComponent from './Modal/ModalComponent';
import { ConstantClass } from '../utils/Constant';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function DialogEndGame(props) {
  const {
    point,
    navigation,
    visible,
    setVisible,
    onResetGame,
    dispatch,
  } = props;

  (async () => {
    try {
      const pointAnonymous = await AsyncStorage.getItem(
        ConstantClass.AnonymousPoint);
      if (!pointAnonymous) {
        AsyncStorage.setItem(ConstantClass.AnonymousPoint, point.toString());
        return;
      }
      if (pointAnonymous < point) {
        AsyncStorage.setItem(ConstantClass.AnonymousPoint, point.toString());
        return;
      }
    } catch (e) {
      console.log(e);
    }
  })();

  let handleExit = () => {
    dispatch({ type: 'logout' });
    setVisible(false);
    navigation.replace('Home', {
      goHome: true,
    });
  };
  let handleReload = () => {
    setVisible(false);
    onResetGame()
    dispatch({ type: 'reset' });
  };

  function getLabel(point = 0) {
    let labelLever
    if (point <= 50) {
      labelLever = 'Tiểu học';
    } else if (point <= 150) {
      labelLever = 'Trung học';
    } else if (point <= 300) {
      labelLever = 'Đại học';
    } else if (point <= 500) {
      labelLever = 'Người đi làm';
    } else {
      labelLever = 'Kế toán trưởng';
    }
    return labelLever
  }

  const labelLever = (
    <View style={styles.contentModal}>
      <Text>Chúc mừng bạn đã hoàn thành trò chơi</Text>
      <Text>Lever : {getLabel(props.point)}</Text>
      <Text>Điểm: {point}</Text>
    </View>
  );

  return (
    <ModalComponent
      modalVisible={visible}
      setModalVisible={setVisible}
      onLogout={handleExit}
      onResume={handleReload}
      buttonLeftText={'Chơi lại'}
      buttonRightText={'Thoát game'}
      title={'Game đã kết thúc'}
      content={labelLever}
    />
  );
}
