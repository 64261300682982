import React from 'react';
import 'react-native-get-random-values';
import AppContainer from './AppContainer';
import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import {lightBlue500} from 'react-native-paper/src/styles/colors';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import './config/globalConfig';
import './config/firebaseConfig';
import {enableScreens} from 'react-native-screens';
import axiosConfig from './config/axios';
import {RankProvider} from './context/RankContext';
import {useFonts} from 'expo-font';
import {UserProvider} from './context/UserContext';
import * as SplashScreen from 'expo-splash-screen';
import AnimatedAppLoader from './components/AnimatedAppLoader/AnimatedAppLoader';
import "./components/common/fixTime"

axiosConfig();
enableScreens();
// Instruct SplashScreen not to hide yet, we want to do this manually
SplashScreen.preventAutoHideAsync().catch(
    () => { /* reloading the app might trigger some race conditions, ignore them */
    });

function App(props) {
  const [loaded] = useFonts({
    Play: require('./assets/fonts/Play-Regular.ttf'),
    'Playb': require('./assets/fonts/Play-Bold.ttf'),
    'VT': require('./assets/fonts/VT323-Regular.ttf'),
  });

  if (!loaded) {
    return null;
  }

  // console.log({splash: Constants.manifest.splash.image});
  return (
      <AnimatedAppLoader image={require('./assets/splash.png')}>
        <SafeAreaProvider>
          <PaperProvider theme={theme}>
            <RankProvider>
              <UserProvider>
                <AppContainer/>
                {/*<View style={styles.iconInput}>*/}
                {/*  <View>*/}

                {/*  </View>*/}
                {/*</View>*/}
              </UserProvider>
            </RankProvider>
          </PaperProvider>
        </SafeAreaProvider>
      </AnimatedAppLoader>
  );
}

export default App;

const theme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    ...DefaultTheme.colors,
    primary: lightBlue500,
    accent: 'orange',
  },
  // fonts: configureFonts({
  //       regular: {
  //         fontFamily: 'Play',
  //         fontWeight: 'normal',
  //       },
  //       medium: {
  //         fontFamily: 'Playb',
  //         fontWeight: 'normal',
  //       },
  //     },
  // ),
};

// const styles = StyleSheet.create({
//   iconInput: {
//     position: 'absolute',
//     right: '5%',
//     bottom: '11%',
//   },
//
// });
