import * as React from 'react';
import { useRef, useState } from 'react';
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native';
import TimeComponent from './TimeComponent';
import MoneyComponent from './MoneyComponent';
import DraggMoney from './Draggable';
import images from './common';
import ModalComponent from './Modal/ModalComponent';
import DialogEndGame from './DiglogEndGame';
import { dofMoney, iosPosition, results } from './common/constants';
import ResultAnimate from './ResultAnimate/ResultAnimate';
import { useBackHandler } from '@react-native-community/hooks';
import calcDiff from './common/calcDiff';
import giveBackMoney from './common/giveBackMoney';
import calcResultByTimePaid from './common/calcResultByTimePaid';
import DraggTips from './Tips/DraggTips';
import ButtonCustom from './Custom/ButtonCustom';
import { AntDesign } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import Constants from "expo-constants";

const _ = require('lodash');
const { RandomMoney } = require('../utils/Random');
const { ConstantClass } = require('../utils/Constant');

export const reducer = (state, action) => {
  switch (action.type) {
    case 'onUpdate': {
      return {
        ...state,
        duration: action.data.duration,
        key: action.data.key,
        initialRemainingTime: action.data.initialRemainingTime,
        isPlaying: action.data.isPlaying,
        received: action.data.received,
        start: action.data.start || new Date().getTime(),
        // perfectList: action.data.perfectList,
      };
    }
    case 'received': {
      return { ...state, received: action.received };
    }
    case 'duration': {
      return { ...state, duration: action.duration };
    }
    case 'key': {
      return { ...state, key: action.key };
    }
    case 'remainingTime': {
      return { ...state, remainingTime: action.remainingTime };
    }
    case 'initialRemainingTime': {
      return { ...state, initialRemainingTime: action.initialRemainingTime };
    }
    case 'reset': {
      return {
        duration: ConstantClass.TimeDefault,
        key: (state?.key || 0) + 1,
        initialRemainingTime: ConstantClass.TimeDefault,
        remainingTime: null,
        isPlaying: true,
        received: [],
        start: new Date().getTime(),
        // perfectList: [],
      };
    }
    case 'logout': {
      return {
        duration: ConstantClass.TimeDefault,
        key: (state?.key || 0) + 1,
        initialRemainingTime: ConstantClass.TimeDefault,
        remainingTime: null,
        isPlaying: false,
        received: [],
        start: new Date().getTime(),
        // perfectList: [],
      };
    }
  }
};

export default function PlayScreen(props) {
  const [visible, setVisible] = React.useState(false);
  const [checkReload, setCheckReload] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    duration: ConstantClass.TimeDefault,
    key: 1,
    initialRemainingTime: ConstantClass.TimeDefault,
    remainingTime: null,
    isPlaying: true,
    received: [],
    start: new Date().getTime(),
    // perfectList: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const [tableY, setTableY] = React.useState(null);
  const timeRef = React.useRef({});
  const DropZoneRef = React.useRef(null);
  const PointZoneRef = React.useRef(null);
  const [game, setGame] = useState(initDefault());
  const moreRef = useRef({})
  const comboRef = useRef(0)
  const [pause, setPause] = useState(false)

  // disable Android back handler
  useBackHandler(() => {
    return true;
  });

  const isDropedInZone = (y) => {
    let realY = y //- Constants.statusBarHeight;
    return realY && DropZoneRef.current && PointZoneRef.current && realY >= (PointZoneRef.current.height + Constants.statusBarHeight / 3) &&
      realY <= (PointZoneRef.current.height + DropZoneRef.current.height - Constants.statusBarHeight / 2);
  };
  const onResetGame = () => {
    setGame(initDefault());
  };
  // khi nhan exit
  const onExit = () => {
    setModalVisible(true);
    setPause(true)
    const data = {
      duration: state.duration,
      key: state.key + 1,
      initialRemainingTime: timeRef.current.remainingTime,
      remainingTime: null,
      isPlaying: false,
      received: state.received,
    };
    dispatch({ type: 'onUpdate', data });
    timeRef.current.remainingTime = null;
  };

  const onLogout = () => {
    setModalVisible(false);
    dispatch({ type: 'logout' });
    timeRef.current.remainingTime = null;
    initDefault();
    props.navigation.replace('Home');
  };

  const onResume = () => {
    setModalVisible(false);
    setPause(false)
    const data = {
      duration: state.duration,
      key: state.key + 1,
      initialRemainingTime: state.initialRemainingTime,
      remainingTime: null,
      isPlaying: true,
      received: state.received,
    };
    dispatch({ type: 'onUpdate', data });
    timeRef.current.remainingTime = null;
  };

  const onTraTien = async () => {
    if (calcDiff(state.received, game.payment) >= 0) {
      setLoading(true);
      if (loading)
        return;
      // paid money clear
      game.money.count -= state.received.length;
      state.received.forEach(val => {
        game.money[val.split("_")[0]] -= 1;
      });
      const diff = calcDiff(state.received, game.payment);
      const timePaid = (new Date().getTime() - state.start) / 1000;
      const result = calcResultByTimePaid(
        clickBtnTraTien(diff, state.received), timePaid);
      if (result.id === 1) {
        // xuat sac
        comboRef.current += 1;
      } else {
        comboRef.current = 0;
      }
      setResult({ ...result, combo: comboRef.current });

      game.point += result.point;
      setGame({ ...game });
      const lever = checkPointAndRanDom(game.point);
      const { giveBack } = giveBackMoney(diff, lever);
      //save maxCombo
      if (game?.maxCombo < comboRef.current) {
        game.maxCombo = comboRef.current;
        setGame({ ...game });
      }
      dispatch({ type: 'onUpdate', data: { ...state, isPlaying: false } });
      setTimeout(async () => {
        let nextPayment = 0;
        if (lever > 1) {
          nextPayment = Math.floor(
            Math.random() * (lever - 1) * 100) + (lever - 1) * 50;
        } else {
          nextPayment = Math.floor(Math.random() * lever * 50) + 1;
        }
        Object.keys(giveBack).map(key => {
          game.money[key] += giveBack[key] || 0;
        });
        game.money.count = dofMoney.reduce((count, key) => {
          return count + game.money[`${key}k`] || 0;
        }, 0);
        let total = dofMoney.reduce((count, key) => {
          return count + game.money[`${key}k`] * key || 0;
        }, 0);
        game.payment = nextPayment;
        if (total < nextPayment) {
          // give more money
          // tang them
          let totalGive = Math.floor(Math.random() * 100)
          while (totalGive + total <= nextPayment) {
            totalGive += Math.floor(Math.random() * 100) + ((lever < 4) ? 100 : 300);
          }
          const more = giveBackMoney(totalGive, lever)
          moreRef.current = more.giveBack
          Object.keys(more.giveBack).map(key => {
            game.money[key] += more.giveBack[key] || 0;
          });
          game.money.count += more.count

        }
        game.startPayment = new Date().getTime()
        setGame({ ...game });
        const data = {
          duration: state.duration +
            result.time,
          key: state.key + 1,
          initialRemainingTime: timeRef.current.remainingTime +
            result.time,
          remainingTime: null,
          isPlaying: true,
          received: [],
          start: new Date().getTime()
        };
        dispatch({ type: 'onUpdate', data });
        timeRef.current.remainingTime = null;
      }, 500);
      setTimeout(() => {
        setLoading(false);
        moreRef.current = {}
      }, 1500);
    }
  };

  return (
    <View style={styles.container}>
      {pause ?
        <Foundation name="play" size={30} color="black"
                    style={styles.iconPause}
        />
        :
        <AntDesign name="pause"
                   size={30}
                   color="black"
                   onPress={onExit}
                   style={styles.iconPause}
        />
      }
      {
        modalVisible && <ModalComponent
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          onLogout={onLogout}
          onResume={onResume}
        />
      }
      {
        visible &&
        <DialogEndGame
          point={game.point}
          setCheckReload={setCheckReload}
          initDefault={initDefault}
          checkReload={checkReload}
          dispatch={dispatch}
          visible={visible}
          setVisible={setVisible}
          onResetGame={onResetGame}
          navigation={props.navigation}/>
      }
      <View style={[styles.container2]}>
            <View style={styles.zonePoint} onLayout={event => {
              PointZoneRef.current = event.nativeEvent.layout;
            }}>
        <View style={Platform.OS === 'ios' ? styles.pointIOS : styles.point}>
          <MoneyComponent money={game.payment}
                          loading={loading}
                          state={state}
          />
          <TimeComponent ref={timeRef} point={game.point}
                         state={state} dispatch={dispatch}
                         setVisible={setVisible}
                         navigation={props.navigation}
                         countMoney={game.money.count}
                         maxCombo={game.maxCombo}
          />
        </View>
</View>
            <View style={styles.zoneReceiving}>
        <View style={styles.receivingZone} onLayout={event => {
          if (event.nativeEvent.layout) {
            setTableY(event.nativeEvent.layout.height);
            DropZoneRef.current = event.nativeEvent.layout;
          }
        }}>
          <ResultAnimate result={result}/>
        </View>
</View>
            <View style={styles.zoneMoney}>
        {
          !visible ?
            <View
              style={Platform.OS === 'ios' ?
                styles.iosBlockBody :
                styles.blocksBody}
              pointerEvents={(Platform.OS === 'ios' && loading) ?
                'none' :
                'auto'}
              // pointerEvents={loading ? "none" : "auto"}
            >
              <ButtonCustom
                text={'Trả tiền'}
                width={200}
                height={30}
                actions={onTraTien}
                disabled={loading ||
                calcDiff(state.received, game.payment) < 0}
                containerStyle={styles.traTienBtnContainer}
              />
              <DraggTips/>
              <View style={Platform.OS === 'ios'
                ? styles.iosChooseMoney
                : styles.chooseMoney}>
                {
                  [
                    '1k',
                    '2k',
                    '5k',
                    '10k',
                    '20k',
                    '50k',
                    '100k',
                    '200k',
                    '500k'].map(
                    (value, index) => {
                      let randomIdxCp = game.money[value] -
                        state.received.filter(
                          x => x?.split("_")[0] === value).length;
                      let randomIdx = game.money[value];
                      const moreGive = moreRef.current[value] || 0

                      if (Platform.OS === 'ios')
                                                return handleDraggable(randomIdx, value,
                                                    game.money, dispatch, state.received).map((val, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Text
                                                            style={[
                                                                styles.numberMoney,
                                                                {...iosPosition[value]}]}>{randomIdxCp >
                                                        0
                                                            ?
                                                            randomIdxCp
                                                            :
                                                            ''}</Text>
                                                        <DraggMoney {...val} loading={loading}
                                                                    style={iosPosition[value]}
                                                                    isDropedInZone={isDropedInZone}
                                                                    khay={tableY}
                                                                    isMore={randomIdx - idx - 1 < moreGive}
                                                        />
                                                    </React.Fragment>
                                                ));
                                            return (
                                                <View style={styles.containerMoney} key={index}>
                                                  <Text
                                                    style={styles.numberMoney}>{randomIdxCp > 0 ?
                                                    randomIdxCp :
                                                    ''}</Text>
                                                    {
                                                        handleDraggable(randomIdx, value,
                                                            game.money,
                                                            dispatch, state.received).map((val, idx) => (
                                                            <DraggMoney {...val} loading={loading}
                                                                        isDropedInZone={isDropedInZone}
                                                                        khay={tableY}
                                                                        isMore={randomIdx - idx - 1 < moreGive}
                                                            />
                                                        ))
                                                    }
                                                </View>
                                            );
                                        })
                                }
                            </View>
                        </View>
                        :
                        <View style={styles.blocksBody}/>
                }
            </View>
          </View>
          {/*<View style={styles.container}>*/}
          {/*  <View style={Platform.OS === 'ios' ? styles.pointIOS : styles.point}>*/}
          {/*    <MoneyComponent money={game.payment}*/}
          {/*                    loading={loading}*/}
          {/*                    state={state}*/}
          {/*    />*/}
          {/*    <TimeComponent ref={timeRef} point={game.point}*/}
          {/*                   state={state} dispatch={dispatch}*/}
          {/*                   setVisible={setVisible}*/}
          {/*                   navigation={props.navigation}*/}
          {/*                   countMoney={game.money.count}*/}
          {/*                   maxCombo={game.maxCombo}*/}
          {/*        />*/}
          {/*    </View>*/}

          {/*    <View style={styles.receivingZone} onLayout={event => {*/}
          {/*        if (event.nativeEvent.layout) {*/}
          {/*            setTableY(event.nativeEvent.layout.height);*/}
          {/*            DropZoneRef.current = event.nativeEvent.layout;*/}
          {/*        }*/}
          {/*    }}>*/}
          {/*        <ResultAnimate result={result}/>*/}
          {/*    </View>*/}

          {/*    {*/}
          {/*        !visible ?*/}
          {/*            <View*/}
          {/*                style={Platform.OS === 'ios' ?*/}
          {/*                    styles.iosBlockBody :*/}
          {/*                    styles.blocksBody}*/}
          {/*                pointerEvents={(Platform.OS === 'ios' && loading) ?*/}
          {/*                    'none' :*/}
          {/*                    'auto'}*/}
          {/*                // pointerEvents={loading ? "none" : "auto"}*/}
          {/*            >*/}
          {/*                <ButtonCustom*/}
          {/*                  text={'Trả tiền'}*/}
          {/*                  width={200}*/}
          {/*                  height={30}*/}
          {/*                  actions={onTraTien}*/}
          {/*                  disabled={loading ||*/}
          {/*                  calcDiff(state.received, game.payment) < 0}*/}
          {/*                  containerStyle={styles.traTienBtnContainer}*/}
          {/*                />*/}
          {/*              <DraggTips/>*/}
          {/*                <View style={Platform.OS === 'ios'*/}
          {/*                    ? styles.iosChooseMoney*/}
          {/*                    : styles.chooseMoney}>*/}
          {/*                    {*/}
          {/*                        [*/}
          {/*                            '1k',*/}
          {/*                            '2k',*/}
          {/*                            '5k',*/}
          {/*                            '10k',*/}
          {/*                            '20k',*/}
          {/*                            '50k',*/}
          {/*                            '100k',*/}
          {/*                            '200k',*/}
          {/*                            '500k'].map(*/}
          {/*                            (value, index) => {*/}
          {/*                                let randomIdxCp = game.money[value] -*/}
          {/*                                    state.received.filter(*/}
          {/*                                        x => x?.split("_")[0] === value).length;*/}
          {/*                                let randomIdx = game.money[value];*/}
          {/*                                const moreGive = moreRef.current[value] || 0*/}

          {/*                                if (Platform.OS === 'ios')*/}
          {/*                                    return handleDraggable(randomIdx, value,*/}
          {/*                                        game.money, dispatch, state.received).map((val, idx) => (*/}
          {/*                                        <React.Fragment key={idx}>*/}
          {/*                                            <Text*/}
          {/*                                                style={[*/}
          {/*                                                    styles.numberMoney,*/}
                                { /*                                                    {...iosPosition[value] }]}>{randomIdxCp >*/}
          {/*                                            0*/}
          {/*                                                ?*/}
          {/*                                                randomIdxCp*/}
          {/*                                                :*/}
          {/*                                                ''}</Text>*/}
          {/*                                            <DraggMoney {...val} loading={loading}*/}
          {/*                                                        style={iosPosition[value]}*/}
          {/*                                                        isDropedInZone={isDropedInZone}*/}
          {/*                                                        khay={tableY}*/}
          {/*                                                        isMore={randomIdx - idx - 1 < moreGive}*/}
          {/*                                            />*/}
          {/*                                        </React.Fragment>*/}
          {/*                                    ));*/}
          {/*                                return (*/}
          {/*                                    <View style={styles.containerMoney} key={index}>*/}
          {/*                                        <Text*/}
          {/*                                            style={styles.numberMoney}>{randomIdxCp ?*/}
          {/*                                            randomIdxCp :*/}
          {/*                                            ''}</Text>*/}
                          {/*                                        {*/}
          {/*                                            handleDraggable(randomIdx, value,*/}
          {/*                                                game.money,*/}
          {/*                                                dispatch, state.received).map((val, idx) => (*/}
          {/*                                                <DraggMoney {...val} loading={loading}*/}
          {/*                                                            isDropedInZone={isDropedInZone}*/}
          {/*                                                            khay={tableY}*/}
          {/*                                                            isMore={randomIdx - idx - 1 < moreGive}*/}
          {/*                                                />*/}
          {/*                                            ))*/}
                          {/*                                        }*/}
          {/*                                    </View>*/}
          {/*                                );*/}
          {/*                            })*/}
                {/*                    }*/}
          {/*                </View>*/}
          {/*            </View>*/}
          {/*            :*/}
          {/*            <View style={styles.blocksBody}/>*/}
        {/*    }*/}

      {/*</View>*/}
    </View>
  );
};

const handleDraggable = (n, value, copyRandom, dispatch, received) => {
  let str = [];
  for (let i = 0; i < n; i++) {
    str.push({
      key: `${value}` + i,
      index: `${value}_${i}`,
      src: images[value],
      value: value,
      copyRandom: copyRandom,
      dispatch: dispatch,
      received: received,
    });
  }
  return str;
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function initDefault() {
  return {
    time: ConstantClass.TimeDefault,
    point: ConstantClass.PointDefault,
    money: RandomMoney(),
    payment: Math.floor(Math.random() * 50) + 1,
    maxCombo: 0,
  };
}

function checkPointAndRanDom(point) {
  if (point <= 50) return 1;
  if (point <= 150) return 2;
  if (point <= 300) return 3;
  if (point <= 500) return 4;
  if (point > 1000) {
    return Math.floor(Math.random() * 6) + 10;
  } else {
    return Math.floor(Math.random() * 6) + 4
  }
}

function clickBtnTraTien(moneyDiff) {
  let result;
  const { count } = giveBackMoney(moneyDiff);
  if (moneyDiff === 0) {

    result = results[0];
  } else if (moneyDiff % 10 === 0 && count <= 2) {

    result = results[1];
  } else if (moneyDiff % 10 !== 0 && count <= 3) {

    result = results[2];
  } else {

    result = results[3];
  }

  return result;
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    paddingHorizontal: 5,
  },
  container2: {
    position: 'relative',
    flex: 1,
    paddingHorizontal: 5,
    flexDirection: "column"
  },
  zonePoint: {
    flex: 1.3,
    justifyContent: 'center'
  },
  zoneReceiving: {
    flex: 1.2,
    justifyContent: 'center'
  },
  zoneMoney: {
    flex: 2.5,
    justifyContent: 'center'
  },numberMoney: {
    position: 'absolute',
    top: 1.5,
    left: 11.5,
    zIndex: 99,
    color: 'black',
    fontWeight: 'bold',
    fontSize: 17,
    textShadowColor: '#f8f8ff',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    textAlign: 'center',
  },
  point: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  pointIOS: {
    flex: 0.8,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  receivingZone: {
    flex: 1,
    height: 300,
    backgroundColor: '#f3c7a7',
    borderRadius: 20,
    borderWidth: 3,
    borderColor: '#e0ad83',
    // elevation: -100
  },
  blocksBody: {
    flex: 1.1,
    marginTop: 10,
    alignItems: 'center',
  },
  iosBlockBody: {
    flex: 1.1,
    marginTop: 10,
  },
  imageMoney: {
    alignItems: 'center',
    marginBottom: 10,
    width: 100,
    height: 50,
    marginLeft: 5,
  },
  traTienBtn: {
    // borderColor: '#e0ad83',
    // backgroundColor: '#fad1b4',
    // borderWidth: 2,
    // padding: 10,
    width: 130,
    // borderRadius: 20,
    marginBottom: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonText: {
    width: 100,
    fontSize: 25,
    color: '#111',
    textAlign: 'center',
  },
  containerMoney: {
    position: 'relative',
    width: 110,
    height: 60,
    margin: 'auto',
  },
  chooseMoney: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: -10,
    // backgroundColor: '#fadbc5',
    // borderRadius: 20,
    // borderWidth: 5,
    // borderColor: '#e0ad83',
  },
  iosChooseMoney: {
    //backgroundColor: '#fadbc5',
    //borderRadius: 20,
    //borderWidth: 5,
    //borderColor: '#e0ad83',
    height: 200,
    marginTop: -10
  },
  iconLogout: {
    position: 'absolute',
    right: 3,
    top: 3,
    zIndex: 3,
  },
  traTienBtnContainer: {
    marginTop: 2,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconPause: {
    position: 'absolute',
    left: Dimensions.get('screen').width - 40,
    top: 3,
    zIndex: 3,
    // transform: [
    //   {
    //     rotate: '180deg',
    //   },
    // ],
  },
});

