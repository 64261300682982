import * as SplashScreen from 'expo-splash-screen';
import {Asset} from 'expo-asset';
import React from 'react';
import Constants from 'expo-constants';
import {Animated, StyleSheet, View} from 'react-native';
import AppLoading from 'expo-app-loading';

export default function AnimatedAppLoader({children, image}) {
  const [isSplashReady, setSplashReady] = React.useState(false);

  const startAsync = React.useMemo(
      // If you use a local image with require(...), use `Asset.fromModule`
      () => () => Asset.fromModule(image).downloadAsync(),
      [image],
  );

  const onFinish = React.useMemo(() => setSplashReady(true), []);

  if (!isSplashReady) {
    return (
        <AppLoading
            // Instruct SplashScreen not to hide yet, we want to do this manually
            autoHideSplash={false}
            startAsync={startAsync}
            onError={console.error}
            onFinish={onFinish}
        />
    );
  }

  return <AnimatedSplashScreen image={image}>{children}</AnimatedSplashScreen>;
}

function AnimatedSplashScreen({children, image}) {
  const animation = React.useMemo(() => new Animated.Value(1), []);
  const [isAppReady, setAppReady] = React.useState(false);
  const [isSplashAnimationComplete, setAnimationComplete] = React.useState(
      false,
  );

  React.useEffect(() => {
    if (isAppReady) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => setAnimationComplete(true));
    }
  }, [isAppReady]);

  const onImageLoaded = React.useMemo(() => async () => {
    try {
      await SplashScreen.hideAsync();
      // Load stuff
      await Promise.all([]);
    } catch (e) {
      // handle errors
    } finally {
      setAppReady(true);
    }
  });

  return (
      <View style={styles.container}>
        {isAppReady && children}
        {!isSplashAnimationComplete && (
            <Animated.View
                pointerEvents="none"
                style={[
                  StyleSheet.absoluteFill,
                  {
                    backgroundColor: Constants.manifest.splash.backgroundColor,
                    opacity: animation,
                  },
                ]}
            >
              <Animated.Image
                  style={[styles.imgAnimated,
                    {
                      transform: [
                        {
                          scale: animation,
                        },
                      ],
                    }
                  ]}
                  source={image}
                  onLoadEnd={onImageLoaded}
                  fadeDuration={0}
              />
            </Animated.View>
        )}
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  imgAnimated: {
    width: '100%',
    height: '100%',
    resizeMode: Constants.manifest.splash.resizeMode ||
        'contain',
    // transform: [
    //   {
    //     scale: animation,
    //   },
    // ],
  }
})