import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Dimensions, StyleSheet } from 'react-native';

function IconExitCustom({ onExit }) {
  return (
    <Ionicons
      style={styles.iconLogout}
      name="md-close-circle"
      size={35}
      color="black"
      onPress={onExit}
    />
  );
}

export default IconExitCustom;

const styles = StyleSheet.create({
  iconLogout: {
    position: 'absolute',
    left: Dimensions.get('screen').width - 40,
    top: 3,
    zIndex: 3,
    // transform: [
    //   {
    //     rotate: '180deg',
    //   },
    // ],
  },
});
