import React, {useContext} from 'react';
import {
  Image,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  VirtualizedList,
} from 'react-native';
import images from './common';
import RankContext from '../context/RankContext';
import AppLoading from 'expo-app-loading';
import * as Animatable from 'react-native-animatable';
import IconExitCustom from './Custom/IconExitCustom';

// const sortPoint = (array) => {
//   return array.sort(function(a, b) {
//     return b.point - a.point;
//   });
// };

const Item = ({item, index}) => {
  return (
      <View style={styles.bodyList}>
        <Animatable.Text animation="pulse" easing="ease-out"
                         iterationCount="infinite"
                         style={Platform.OS === 'ios' ?
                             styles.RateStars :
                             styles.RateStarsAndroid}>
          <Text style={styles.textIndex}>{index + 1}</Text>
        </Animatable.Text>
        <View>
          <Image source={item?.photoURL ?
              {uri: `${item.photoURL}`} :
              images['avt']} style={styles.imgAvt}/>
        </View>
        <View style={Platform.OS === 'ios' ?
            styles.textIndex :
            styles.textMainAndroid}>
          <Text style={styles.textUser}> {item.nickname ||
          'No name'}</Text>
          <Text style={styles.textUser}> Điểm: {item.point}</Text>
        </View>
      </View>
  );
};

function RankScreen(props) {
  const {ranks, onNext, hasMore} = useContext(RankContext);

  return (
      <SafeAreaView style={styles.container}>
        <IconExitCustom
            onExit={() => props.navigation.replace('Home')}
        />
        <View style={styles.containerView}>
          {
            !ranks?.length ?
                <AppLoading/>
                :
                <VirtualizedList
                    data={ranks}
                    hasMore={hasMore}
                    renderItem={({item, index}) => <Item item={item}
                                                         index={index}/>}
                    keyExtractor={item => item.id}
                    getItemCount={d => d.length}
                    getItem={(d, idx) => d[idx]}
                    onEndReached={onNext}
                    onEndReachedThreshold={0.5}
                />
          }
        </View>
      </SafeAreaView>
  );
};

export default RankScreen;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: 'transparent',
  },
  containerView: {
    flex: 1,
    // backgroundColor: '#fff',
    // overflow: 'scroll',
    // position: 'relative',
    marginTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
    // paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    marginHorizontal: 20,
    margin: 'auto',
  },
  RateStars: {
    position: 'absolute',
    top: 15,
    right: 20,
  },
  RateStarsAndroid: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  textIndex: {
    fontSize: 35,
    fontFamily: 'VT',
    color: '#FFF',
  },
  instructions: {
    textAlign: 'center',
    color: 'red',
    fontSize: 26,
    marginHorizontal: 15,
    marginBottom: 30,
    marginTop: 10,
    fontFamily: 'VT',
  },
  imgAvt: {
    marginRight: 10,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  bodyList: {
    backgroundColor: '#a3adcb',
    padding: 10,
    marginBottom: 20,
    borderRadius: 50,
    flexDirection: 'row',
  },
  textUser: {
    fontSize: 24,
    fontFamily: 'VT',
  },
  textMain: {
    flex: 1,
  },
  textMainAndroid: {
    flex: 1,
    marginTop: -10,
  },
});
