import {auth, db} from './firebaseConfig';
import {getGlobal} from 'reactn';

const DATA_COL = 'plays';
const USER_COL = 'users';
const RANK_COL = 'ranks';

export function getUser(key) {
  const user = getGlobal().user || auth.currentUser?.toJSON();
  if (!user) throw Error('no user, please login again.');
  return key ? user[key] : user;
}

export const playColRef = () => {
  return db.collection(DATA_COL);
};

export const userColRef = () => {
  return db.collection(USER_COL);
};

export const rankColRef = () => {
  return db.collection(RANK_COL);
};

export const rankDocRef = () => {
  if (!getUser('uid'))
    return null;

  return db.collection(RANK_COL).doc(getUser('uid'));
};

export const myDoc = () => {
  if (!getUser('uid'))
    return null;

  return db.collection(USER_COL).doc(getUser('uid'));
};

export const myData = () => {
  if (!getUser('uid'))
    return null;
  return playColRef().where('uid', '==', getUser('uid'));
};

