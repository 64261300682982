import React from 'react';
import { useGlobal } from 'reactn';
import { Easing, Image, ImageBackground, Platform, Pressable, StyleSheet, Text, View, } from 'react-native';
import { useBackHandler } from '@react-native-community/hooks';
import AnimatedCustom from './ResultAnimate/AnimatedCustom';
import { ConstantClass } from '../utils/Constant';
import ModalComponent from './Modal/ModalComponent';
import AsyncStorage from "@react-native-async-storage/async-storage";

function ResultScreen(props) {
  const { route } = props;
  const [user] = useGlobal('user')
  const [open, setOpen] = React.useState(null);

  //
  // console.log("high score", route.params.highScore?.point)
  // console.log("point", route.params.point)

  const isUserLogin = () => {
    return Boolean(user?.uid)
  }
  // disable Android back handler
  useBackHandler(() => {
    return true;
  });

  (async () => {
    if (user?.uid)
      return;
    try {
      const pointAnonymous = await AsyncStorage.getItem(ConstantClass.AnonymousPoint);
      const maxComboAnonymous = await AsyncStorage.getItem(ConstantClass.AnonymousCombo);
      if (!pointAnonymous || pointAnonymous < route.params.point) {
        await AsyncStorage.setItem(ConstantClass.AnonymousPoint, route.params.point.toString());
      }
      if (!maxComboAnonymous || maxComboAnonymous < route.params.maxCombo) {
        await AsyncStorage.setItem(ConstantClass.AnonymousCombo, route.params.maxCombo.toString());
      }
    } catch (e) {
      console.log(e);
    }
  })();

  const handleExit = () => {
    if (!isUserLogin())
      return setOpen('Home')
    props.navigation.replace('Home');
  };

  const handleBXH = () => {
    if (!isUserLogin())
      return setOpen('Rank')
    props.navigation.replace('Rank');
  };

  const handleReload = () => {
    if (!isUserLogin())
      return setOpen('Play')
    props.navigation.replace('Play');
  };

  const goToLogin = () => {
    props.navigation.replace('Setting')
  }

  const onCancel = () => {
    props.navigation.replace(open)
  }

  function getLabel(point = 0) {
    let labelLever;
    if (point <= 50) {
      labelLever = 'Tiểu học';
    } else if (point <= 150) {
      labelLever = 'Trung học';
    } else if (point <= 300) {
      labelLever = 'Đại học';
    } else if (point <= 500) {
      labelLever = 'Người đi làm';
    } else {
      labelLever = 'Kế toán trưởng';
    }
    return labelLever;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Rèn luyện kĩ năng trả tiền</Text>
      <ImageBackground source={require('../assets/bg.jpg')} style={styles.image}/>
      <View style={styles.content}>
        {
          route.params.point > route.params.highScore?.point ?
            <View style={styles.textHighScore}>
              <AnimatedCustom easingValue={Easing.inOut(Easing.elastic(1))}
                              timeout={1700}>
                <Image style={styles.highScore} source={require('../assets/new-high-score.png')}/>
              </AnimatedCustom>
            </View>
            :
            null
        }
        <AnimatedCustom easingValue={Easing.inOut(Easing.elastic(1))}
                        timeout={500}>
          <Text style={styles.text}>Kết quả cuộc chơi!</Text>
        </AnimatedCustom>

        <AnimatedCustom easingValue={Easing.inOut(Easing.elastic(1))}
                        timeout={800}>
          <Text style={styles.text}>Combo : {route.params.maxCombo}</Text>
        </AnimatedCustom>

        <AnimatedCustom easingValue={Easing.inOut(Easing.elastic(1))}
                        timeout={1200}>
          <Text style={styles.text}>Lever : {getLabel(
            route.params.point)}</Text>
        </AnimatedCustom>

        <AnimatedCustom easingValue={Easing.inOut(Easing.elastic(1))} timeout={1500}>
          <Text style={styles.text}>Điểm: {route.params.point} pt</Text>
        </AnimatedCustom>

        {/*{*/}
        {/*  highScore?.point > route.params.point &&*/}
        {/*  <AnimatedCustom easingValue={Easing.in(Easing.bounce)} timeout={1700}>*/}
        {/*    <View style={styles.textMain}>*/}
        {/*      <Text style={styles.textChildren}>Cố gắng lên nào! bạn đã chiến thắng bản thân</Text>*/}
        {/*    </View>*/}
        {/*  </AnimatedCustom>*/}
        {/*}*/}

        <AnimatedCustom easingValue={Easing.in(Easing.bounce)} timeout={2000}>
          <View style={styles.textMain}>
            <Text style={styles.textChildren}>{getLabel(route.params.point)}</Text>
          </View>
        </AnimatedCustom>

        <View style={styles.buttonActions}>
          <Pressable
            style={[Platform.OS === 'android' ? styles.button : styles.buttonIOS, styles.buttonContinue]}
            onPress={handleReload}
          >
            <Text style={Platform.OS === 'android' ? styles.textStyle : styles.textStyleIOS}>Chơi lại</Text>
          </Pressable>
          <Pressable
            style={[Platform.OS === 'android' ? styles.button : styles.buttonIOS, styles.buttonBXH]}
            onPress={handleBXH}
          >
            <Text style={Platform.OS === 'android' ? styles.textStyle : styles.textStyleIOS}>Bảng xếp hạng</Text>
          </Pressable>
          <Pressable
            style={[Platform.OS === 'android' ? styles.button : styles.buttonIOS, styles.buttonExit]}
            onPress={handleExit}
          >
            <Text style={Platform.OS === 'android' ? styles.textStyle : styles.textStyleIOS}>Thoát game</Text>
          </Pressable>
        </View>
      </View>
      {
        Boolean(open) &&
        <ModalComponent
          modalVisible={Boolean(open)}
          setModalVisible={setOpen}
          title='Bạn chưa đăng nhập!'
          content={<Text style={styles.textDialog}>Dữ liệu chưa được lưu và bạn sẽ không được lên bảng xếp hạng! Bạn có
            muốn đăng nhập ngay không?</Text>}
          buttonLeftText='Đăng nhập'
          buttonRightText='Huỷ bỏ'
          onResume={goToLogin}
          onLogout={onCancel}
          isText={true}
          loading={false}
          disable={false}
        />
      }
    </View>
  );
}

export default ResultScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    top: '30%',
  },
  text: {
    fontSize: 28,
    color: '#ffffff',
    fontFamily: 'VT',
    marginTop: 10,
  },
  textStyleIOS: {
    fontSize: 26,
    color: '#ffffff',
    fontFamily: 'VT',
  },
  textStyle: {
    fontSize: 26,
    color: '#ffffff',
    fontFamily: 'VT',
    marginBottom: 5
  },
  title: {
    fontSize: 30,
    zIndex: 10,
    color: '#e30b0b',
    fontFamily: 'VT',
    position: 'absolute',
    top: 30,
    margin: 'auto',
    textTransform: 'uppercase',
  },
  buttonIOS: {
    borderRadius: 10,
    padding: 5,
    elevation: 2,
    margin: 5,
    minWidth: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 10,
    elevation: 2,
    margin: 5,
    minWidth: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContinue: {
    backgroundColor: '#2196F3',
  },
  buttonExit: {
    backgroundColor: '#cb1717',
  },
  buttonBXH: {
    backgroundColor: '#ffab03',
  },
  buttonActions: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 30,
  },
  textMain: {
    width: 200,
    height: 50,
    borderWidth: 5,
    borderColor: 'red',
    backgroundColor: 'transparent',
    transform: [
      {
        rotate: '-45deg',
      }, {
        translateX: 80,
      },
      {
        translateY: 30,
      },
    ],
    justifyContent: 'center',
    alignItems: 'center',
  },
  textHighScore: {
    position: 'absolute',
    zIndex: 5,
    top: 0,
    left: 200
  },
  textChildren: {
    fontSize: 30,
    color: 'red',
    fontFamily: 'VT',
  },
  textDialog: {
    fontSize: 18,
    fontFamily: 'VT',
  },
  highScore: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    justifyContent: 'center',
  }
});
