import React, { useContext, useState } from 'react';
import { Avatar } from 'react-native-elements';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import UserContext from '../../context/UserContext';
import images from '../common';
import ModalComponent from '../Modal/ModalComponent';
import Icon from 'react-native-vector-icons/Feather';
import { myDoc, rankColRef } from '../../config/firestoreRefs';
import { useGlobal } from 'reactn';
import { db } from '../../config/firebaseConfig';

function UserCustom(props) {
  const { highScore } = useContext(UserContext);
  const [user] = useGlobal('user');
  const [edit, setEdit] = useState(false);
  const [input, setInput] = useState(user?.nickname || '');
  const [loading, setLoading] = useState(false);

  const handleEdit = async () => {
    try {
      if (!input || (input && !input.split(' ').filter(x => x).length)) {
        setInput(user?.nickname || '');
        return;
      }
      const batch = db.batch()
      setLoading(true);
      batch.set(myDoc(), {
        nickname: input,
        updatedAt: new Date(),
      }, { merge: true });

      batch.set(rankColRef().doc(user.uid), {
        nickname: input,
        updatedAt: new Date(),
      }, { merge: true })

      await batch.commit();
    } catch (e) {
      console.log(e.toString());
    } finally {
      setEdit(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setInput(user?.displayName);
    setEdit(false);
  };

  const textInput = (
      <TextInput
          style={styles.textInput}
          autoCompleteType={'name'}
          placeholder="Hãy nhập nick name của bạn!"
          autoFocus={true}
          value={input}
          onChangeText={(text) => setInput(text)}
      />
  );

  return (
      <View style={styles.container}>
        <Avatar
            overlayContainerStyle={styles.avatarStyle}
            size={'medium'}
            source={user?.photoURL ?
                {uri: `${user.photoURL}`} : images['avt']}
        >
          {/*<Avatar.Accessory {...styles.Accessory} />*/}
        </Avatar>
        <View>
          <Text style={styles.nameStyle}>
            Nick name: {user?.nickname}
            {
              !edit &&
              <Icon onPress={() => setEdit(true)} margin={10} name="edit-2"
                    size={20} color="red"/>
            }
          </Text>
          {
            highScore?.point &&
            <Text style={styles.nameStyle}>
              Thành tích tốt nhất: {highScore.point} Điểm
            </Text>
          }
        </View>
        {
          edit &&
          <ModalComponent
              modalVisible={edit}
              setModalVisible={setEdit}
              title='Nhập nick name của bạn'
              content={textInput}
              buttonLeftText='Lưu lại'
              buttonRightText='Huỷ bỏ'
              onResume={handleEdit}
              onLogout={handleCancel}
              isText={false}
              loading={loading}
              disable={!input ||
              (input && !input.split(' ').filter(x => x).length)}
          />
        }
      </View>
  );
}

export default UserCustom;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    padding: 10,
    paddingTop: 25,
    paddingBottom: 25,
    backgroundColor: '#dbdbdb',
    alignItems: 'center',
    position: 'relative',
  },
  avatarStyle: {
    flexGrow: 1,
    flexShrink: 1,
  },
  nameStyle: {
    textAlign: 'center',
    fontSize: 22,
    fontFamily: 'VT',
    marginLeft: 15,
  },
  icon: {
    marginLeft: 5,
    borderWidth: 1,
  },
  textInput: {
    height: 40,
    fontFamily: 'VT',
    fontSize: 20
  }
});
