import React, {useEffect} from 'react';
import {Animated, StyleSheet} from 'react-native';

const AnimatedCustom = (props) => {
  let opacity = new Animated.Value(0);
  const {easingValue, timeout} = props;

  useEffect(() => {
    setTimeout(() => {
      animate();
    }, timeout);
  }, []);

  const animate = easing => {
    opacity.setValue(0);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 1000,
      easing: easingValue,
      useNativeDriver: false
    }).start();
  };

  const animatedStyles = [
    styles.box,
    {
      opacity,
      width: 250,
      height: 50,
    },
  ];

  return (
      <Animated.View style={animatedStyles}>
        {props.children}
      </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default AnimatedCustom;
